import { AdjustableBudgetDto } from 'src/services/budget/dto/adjustableBudget';
import { PagedResultDto } from 'src/services/dto/pagedResultDto';
import http from '../httpService';
import { CheckMutateBudgetOutput } from './dto/checkMutateBudgetOutput';
import { EmployeeBudgetOutput } from './dto/employeeBudgetOutput';
import { GetEmployeeBudgetInput } from './dto/getEmployeeBudgetInput';
import { GetEmployeeSpendingInput } from './dto/getEmployeeSpendingInput';
import { ImportBudgetsInput } from './dto/importBudgetsInput';
import { MutateBudgetItemInput, MutateBudgetsInput } from './dto/mutateBudgetInput';
import { UpdateAllBudgetsInput } from './dto/updateAllBudgetsInput';
import { UpdateAllBudgetsOutput } from './dto/updateAllBudgetsOutput';
import { GetEmployeeMutationsInput } from '@app/services/budget/dto/getEmployeeMutationInput';
import { EmployeeBudgetMutationOutput } from '@app/services/budget/dto/employeeBudgetMutationOutput';

class BudgetService {
    public async adjustBudgets(updateBudgetRequest: UpdateAllBudgetsInput): Promise<UpdateAllBudgetsOutput> {
        const result = await http.put('api/services/app/Budget/UpdateAllBudgets', updateBudgetRequest);
        return result.data.result;
    }

    public async getBudget(
        employeeBudgetRequest: GetEmployeeBudgetInput
    ): Promise<PagedResultDto<EmployeeBudgetOutput>> {
        const result = await http.get('api/services/app/Budget/GetEmployeeBudgets', { params: employeeBudgetRequest });
        return result.data.result;
    }

    public async getSpending(
        employeeSpendingRequest: GetEmployeeSpendingInput
    ): Promise<PagedResultDto<EmployeeBudgetOutput>> {
        const result = await http.get('api/services/app/Budget/GetEmployeeSpending', {
            params: employeeSpendingRequest,
        });
        return result.data.result;
    }

    public async getMutations(
        employeeSpendingRequest: GetEmployeeMutationsInput
    ): Promise<PagedResultDto<EmployeeBudgetMutationOutput>> {
        const result = await http.get('api/services/app/Budget/GetEmployeeBudgetMutations', {
            params: employeeSpendingRequest,
        });
        return result.data.result;
    }

    public async getSampleExport(employerId?: string) {
        return await http.get('api/services/app/Budget/SampleBudgetWorkbook?employerId=' + employerId, {
            responseType: 'arraybuffer',
        });
    }

    public async getBudgetExport(employeeBudgetRequest: GetEmployeeBudgetInput) {
        const result = await http.get('api/services/app/Budget/GetEmployeeBudgetsExport', {
            params: employeeBudgetRequest,
            responseType: 'arraybuffer',
        });
        return result;
    }

    public async getSpendingExport(employeeSpendingRequest: GetEmployeeSpendingInput) {
        const result = await http.get('api/services/app/Budget/GetEmployeeSpendingExport', {
            params: employeeSpendingRequest,
            responseType: 'arraybuffer',
        });
        return result;
    }

    public async getMutationsExport(employeeMutationRequest: GetEmployeeMutationsInput) {
        const result = await http.get('api/services/app/Budget/GetBudgetMutationExport', {
            params: employeeMutationRequest,
            responseType: 'arraybuffer',
        });
        return result;
    }

    public async checkImportBudgets(importBudgetsRequest: ImportBudgetsInput): Promise<CheckMutateBudgetOutput> {
        const result = await http.post('api/services/app/Budget/CheckImportEmployeeBudgets', importBudgetsRequest);
        return result.data.result;
    }

    public async checkImportBudgetsExcel(file: any): Promise<CheckMutateBudgetOutput> {
        let formData = new FormData();
        formData.append('ExcelFile', file);

        const result = await http.post('api/services/app/Budget/CheckImportEmployeeBudgetsExcel', formData);
        return result.data.result;
    }



    public async importBudgets(importBudgetsRequest: ImportBudgetsInput) {
        const result = await http.post('api/services/app/Budget/ImportEmployeeBudgets', importBudgetsRequest);
        return result;
    }

    public async checkMutateBudgets(importBudgetsRequest: MutateBudgetsInput): Promise<CheckMutateBudgetOutput> {
        const result = await http.post('api/services/app/Budget/CheckMutateEmployeeBudgets', importBudgetsRequest);
        return result.data.result;
    }

    public async checkMutateBudgetsExcel(employerId: string, file: any): Promise<CheckMutateBudgetOutput> {
        let formData = new FormData();
        formData.append('ExcelFile', file);
        formData.append( 'employerId', employerId );
        const result = await http.post('api/services/app/Budget/CheckMutateEmployeeBudgetsExcel', formData);
        return result.data.result;
    }
    public async parseBudgetsExcel( file: any): Promise<MutateBudgetItemInput[]> {
        let formData = new FormData();
        formData.append('ExcelFile', file);
        const result = await http.post('api/services/app/Budget/ParseEmployeeBudgetsExcel', formData);
        return result.data.result;
    }

    public async mutateBudgets(importBudgetsRequest: MutateBudgetsInput) {
        return await http.post('api/services/app/Budget/MutateEmployeeBudgets', importBudgetsRequest);
    }

    public async getAdjustableBudgetForEmployer(params: { employerId: string }): Promise<AdjustableBudgetDto> {
        const result = await http.get('api/services/app/Budget/GetAdjustableBudgetForEmployer', {
            params,
        });
        return result.data.result;
    }

    public async updateAdjustableBudget(payload: AdjustableBudgetDto): Promise<AdjustableBudgetDto> {
        const result = await http.put('api/services/app/Budget/UpdateAdjustableBudget', payload);
        return result.data.result;
    }
}

export default new BudgetService();
